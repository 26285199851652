import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../components/Loading";
import { urlClientiRiscattoPremi } from "../components/ApiURL";
import { timestampToItDate } from "../components/Functions";
import Pagination from "../components/Pagination";
import PremiSearchBar from "../components/PremiSearchBar";

var n = 0;

const RiepilogoPremi = () => {
  const [isLoading, setIsloading] = useState(false);
  const [lista, setLista] = useState([]);
  const [filteredList, setFilteredList] = useState(lista);

  // ----- PAGINATIION ----- //
  const fieldsPerPage = 20;
  const totalFields = lista.length;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLast = currentPage * fieldsPerPage;
  const indexOfFirst = indexOfLast - fieldsPerPage;

  const setcurrentepage = (pageNumber) => setCurrentPage(pageNumber);

  // ----- /PAGINATIION ----- //

  // ----- SEARCH ----- //
  const [textToSearch, setTextToSearch] = useState("");
  const [crt, setCtr] = useState("username");

  // ----- /SEARCH ----- //
  const handleRemove = (id) => {
    window.confirm("Vuoi davvero eliminare?") &&
      axios
        .get(urlClientiRiscattoPremi, { params: { action: "del", id } })
        .then(setIsloading(true))
        .then((res) => {
          setLista(res.data);
          setIsloading(false);
        })
        .catch((error) => console.log(error));
  };
  useEffect(() => {
    const getList = async () => {
      await axios
        .get(urlClientiRiscattoPremi)
        .then(setIsloading(true))
        .then((res) => {
          setLista(res.data);
          setIsloading(false);
        });
    };
    getList();
  }, []);

  useEffect(() => {
    if (textToSearch.length > 0) {
      let filteredFields = [];
      if (crt === "datains") {
        filteredFields = lista.filter((item) =>
          timestampToItDate(item.datains).includes(
            textToSearch.toLocaleLowerCase()
          )
        );
      } else if (crt === "username") { //una stringa
        filteredFields = lista.filter((item) =>
          item.username
            .toLocaleLowerCase()
            .includes(textToSearch.toLocaleLowerCase())
        );
      } else { //un numero
        filteredFields = lista.filter((item) => item[crt] === textToSearch);
      }
      setFilteredList(filteredFields);
    } else {
      setFilteredList(lista.slice(indexOfFirst, indexOfLast));
    }
  }, [textToSearch, lista, crt, indexOfFirst, indexOfLast]);

  return (
    <div className="container my-5">
      <PremiSearchBar
        textToSearch={textToSearch}
        crt={crt}
        settexttosearch={setTextToSearch}
        setcrt={setCtr}
      />

      <div className="p-5 mb-4 bg-light rounded-3">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <h2 className="display-5 mb-5 fw-bold">Riepilogo riscatti premi</h2>
            {filteredList.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr className="border-none">
                      <th scope="col">#</th>
                      <th scope="col">Nominativo</th>
                      <th scope="col">Premio</th>
                      <th scope="col">P. Acc</th>
                      <th scope="col">P. Pre</th>
                      <th scope="col">P. Rim</th>
                      <th scope="col">Data Riscatto</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredList.map((item, index) => (
                      <tr key={index}>
                        <td className="fw-bold">{(n = n + 1)}</td>
                        <td className="fw-bold">
                          {item.username.toUpperCase()}
                        </td>
                        <td>{item.premio}</td>
                        <td>{item.puntiaccumulati}</td>
                        <td>{item.puntipremio}</td>
                        <td className="fw-bold">{item.puntirimasti}</td>
                        <td>{timestampToItDate(item.datains)}</td>
                        <td className="text-center">
                          <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            onClick={() => handleRemove(item.id)}
                          >
                            <i className="bi bi-trash-fill"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {textToSearch.length === 0 && (
                  <Pagination
                    currentPage={currentPage}
                    setcurrentepage={setcurrentepage}
                    fieldsPerPage={fieldsPerPage}
                    totalFields={totalFields}
                  />
                )}
              </div>
            ) : (
              <div className="alert alert-info" role="alert">
                Nessun dato trovato
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RiepilogoPremi;
