import React from "react";
import preload from "../Loading.gif";

const Loading = () => {
  return (
    <div className="my-5 d-flex justify-content-center">
      <img src={preload} alt="preload" style={{ width: "80px"}} />
    </div>
  );
};

export default Loading;
