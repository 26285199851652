import React from "react";
import { useNavigate } from "react-router-dom";

export const GoBack = ({ number, color }) => {
  let navigate = useNavigate();
  return (
    <div className="d-flex">
      <button
        type="button"
        className={`btn ${color} btn-lg fs-4 ms-auto`}
        onClick={() => navigate(number)}
      >
        <i className="bi bi-arrow-left"></i>
      </button>
    </div>
  );
};
