import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { timestampToItDate } from "../components/Functions";
import { urlClienti } from "../components/ApiURL";
import Loading from "../components/Loading";
import SearchBar from "../components/SearchBar";
import ReactPaginate from "react-paginate";

const ElencoClienti = () => {
  const [clienti, setClienti] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  // ----- SEARCH ----- //
  const [filteredList, setFilteredList] = useState(clienti);
  const [textToSearch, setTextToSearch] = useState("");
  const [crt, setCtr] = useState("nominativo");

  // ----- /SEARCH ----- //

  const handleRemoveCliente = (id) => {
    window.confirm("Vuoi davvero eliminare ?") &&
      axios
        .get(urlClienti, { params: { action: "del", id: id } })
        .then(setIsloading(true))
        .then((res) => {
          setClienti(res.data);
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(urlClienti)
        .then(setIsloading(true))
        .then((res) => {
          setClienti(res.data);
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, []);

  useEffect(() => {
    if (textToSearch.length > 0) {
      let filteredFields = [];
      if (crt === "datains") {
        filteredFields = clienti.filter((item) =>
          timestampToItDate(item.datains).includes(
            textToSearch.toLocaleLowerCase()
          )
        );
      } else if (crt === "nominativo") {
        filteredFields = clienti.filter((item) =>
          item.nominativo
            .toLocaleLowerCase()
            .includes(textToSearch.toLocaleLowerCase())
        );
      } else {
        filteredFields = clienti.filter(
          (item) =>
            item[crt].toLocaleLowerCase() === textToSearch.toLocaleLowerCase()
        );
      }
      setFilteredList(filteredFields);
      setItemOffset(0);
    } else {
      setFilteredList(clienti);
    }
  }, [textToSearch, clienti, crt]);

  // New pagination
  const itemsPerPage = 20;
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(filteredList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredList.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredList]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % clienti.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className="container my-5">
      <SearchBar
        textToSearch={textToSearch}
        crt={crt}
        settexttosearch={setTextToSearch}
        setcrt={setCtr}
      />

      <div className="p-5 bg-light rounded-3">
        <div className="container-fluid">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <h1 className="display-5 mb-5 fw-bold">Elenco Clienti</h1>
              {currentItems ? (
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="border-none">
                        <th scope="col">#</th>
                        <th scope="col">Nominativo</th>
                        <th scope="col">Email</th>
                        <th scope="col">Psw</th>
                        <th scope="col">Punti</th>
                        <th scope="col">Data ins</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((item, index) => (
                        <tr key={index}>
                          <td className="fw-bold">
                            {itemOffset + (index + 1)}
                          </td>
                          <td className="fw-bold">
                            {item.nominativo.toUpperCase()}
                          </td>
                          <td className="text-truncate">{item.email}</td>
                          <td>{item.psw}</td>
                          <td className="fw-bold">{item.punti}</td>
                          <td>{timestampToItDate(item.datains)}</td>
                          <td>
                            <div className="d-flex justify-content-end align-items-start gap-3">
                              <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={() => handleRemoveCliente(item.id)}
                              >
                                <i className="bi bi-trash-fill"></i>
                              </button>

                              <Link
                                className="btn btn-sm btn-primary"
                                to={`/clientForm/${item.id}`}
                              >
                                <i className="bi bi-three-dots"></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between align-items-start">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="Avanti"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="Indietro"
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link bg-light text-dark"}
                      previousClassName={"page-item"}
                      previousLinkClassName={
                        "page-link bg-primary text-white border-primary"
                      }
                      nextClassName={"page-item"}
                      nextLinkClassName={
                        "page-link bg-primary text-white border-primary"
                      }
                      activeClassName={"active"}
                    />

                    <span className="badge bg-primary text-white py-2 px-3 fs-6">
                      {filteredList.length}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="alert alert-info" role="alert">
                  Nessun dato trovato
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElencoClienti;
