import React from "react";

const Pagination = ({
  currentPage,
  setcurrentepage,
  fieldsPerPage,
  totalFields,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalFields / fieldsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    pageNumbers.length > 1 && (
      <nav className="mb-5 d-flex justify-content-between align-items-center">
        <ul className="pagination flex-wrap">
          {pageNumbers.map((number) => (
            <li key={number} className="page-item">
              <button
                onClick={() => setcurrentepage(number)}
                className={
                  currentPage === number
                    ? "page-link bg-primary text-white"
                    : "page-link"
                }
              >
                {number}
              </button>
            </li>
          ))}
        </ul>

        <span className="badge bg-primary text-white py-2 px-3 fs-6">
          {totalFields}
        </span>
      </nav>
    )
  );
};

export default Pagination;
