import React, { useContext, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { LoggedContext, UserContext } from "./components/Contexts";
import { urlLogin } from "./components/ApiURL";
import logo from "./Logo180.png";
// Login admin and client
const Login = () => {
  const [logged, setLogged] = useContext(LoggedContext);
  const [user, setUser] = useContext(UserContext);

  const [error, setError] = useState("");

  let navigate = useNavigate();

  const formRef = useRef();
  const inputs = useRef([]);

  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    navigate("/"); // per partire sempre dalla home
    const email = inputs.current[0].value;
    const psw = inputs.current[1].value;

    axios.get(urlLogin, { params: { email, psw } }).then((res) => {
      if (res.data.length > 0) {
        const apiUser = res.data[0];
        sessionStorage.setItem("user", JSON.stringify(apiUser));
        setUser(apiUser);
        sessionStorage.setItem("logged", true);
        setLogged(true);
      } else {
        formRef.current.reset();
        setError("Le credenziali inserite non risultano corrette");
      }
    });
  };

  return logged ? (
    <div className="container d-flex justify-content-center">
      <h1 className="display-2">Ciao {user.username}, sei loggato</h1>
      <button className="btn btn-primary" onClick={() => setLogged(false)}>
        Esci
      </button>
    </div>
  ) : (
    <div className="position-fixed top-0 vw-100 vh-100">
      <div className="w-100 h-100 bg-dark bg-opacity-75">
        <div
          className="position-absolute top-50 start-50 translate-middle"
          style={{ width: "90%", maxWidth: "600px" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Login</h5>
                <i className="bi bi-person-fill fs-3"></i>
              </div>

              <div className="modal-body">
                <form
                  className="sign-up-for"
                  onSubmit={handleLogin}
                  ref={formRef}
                >
                  <div className="mb-3">
                    <input
                      name="email"
                      id="loginEmail"
                      type="text"
                      placeholder="Email"
                      className="form-control"
                      ref={addInputs}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      name="psw"
                      id="loginPsw"
                      type="password"
                      placeholder="Password"
                      className="form-control"
                      ref={addInputs}
                      required
                    />
                  </div>
                  {error && <div className="text-danger my-2">{error}</div>}
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-column align-items-center mt-5"
            style={{ color: "#979797" }}
          >
            <div>Via Porta di Mare 7 - 88100 Catanzaro</div>
            <div className="mb-3">Tel. +39.0961.61898</div>
            <img src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
