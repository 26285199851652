import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { timestampToItDateTime } from "../components/Functions";
import { urlPremi } from "../components/ApiURL";
import axios from "axios";
import Loading from "../components/Loading";

// nominativo/totale punti/punti riscatati/data riscatto
const n = 0;
const ElencoPremi = () => {
  const [isLoading, setIsloading] = useState(false);
  const [premi, setPremi] = useState([]);

  const handleRemovePremio = (id) => {
    window.confirm("Vuoi davvero eliminare questo bonus?") &&
      axios
        .get(urlPremi, { params: { action: "del", id } })
        .then(setIsloading(true))
        .then((res) => {
          setPremi(res.data);
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(urlPremi)
        .then(setIsloading(true))
        .then((res) => {
          setPremi(res.data);
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, []);

  return (
    <div className="container my-5">
      <div className="p-5 mb-4 bg-light rounded-3">
        <div className="container-fluid py-5">
          <h1 className="display-5 mb-5 fw-bold">Elenco Premi</h1>
          {isLoading ? (
            <Loading />
          ) : premi.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr className="border-none">
                    <th scope="col">#</th>
                    <th scope="col">Data inserimento</th>
                    <th scope="col">Attivo</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {premi.map((premio) => (
                    <tr key={premio.id}>
                      <td className="fw-bold">{n + 1}</td>
                      <td>{timestampToItDateTime(premio.datains)}</td>
                      <td className="fw-bold">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          defaultChecked={premio.attivo}
                        />
                      </td>
                      <td className="d-flex justify-content-end gap-3 align-items-start">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => handleRemovePremio(premio.id)}
                        >
                          <i className="bi bi-trash-fill"></i>
                        </button>
                        <Link
                          className="btn btn-success"
                          to={`/premio/${premio.id}`}
                        >
                          <i className="bi bi-three-dots"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-info" role="alert">
              Nessun bonus premi trovato
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElencoPremi;
