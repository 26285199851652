import React from "react";

//idcliente
//cliente (nominativo)
//idpremio
//punti
//premio
//datains

const ModuloRiscattoPunti = () => {
  return (
    <div className="jumbotron vertical-center">
      <div className="container">
      <div className="h-100 p-5 text-white bg-dark rounded-3">
          <h2>60 PUNTI</h2>
          <p>Swap the background-color utility and add a `.text-*` color utility to mix up the jumbotron look. Then, mix and match with additional component themes and more.</p>
          <button className="btn btn-outline-light" type="button">Riscatta Punti</button>
        </div>
      </div>
    </div>
  );
};

export default ModuloRiscattoPunti;
