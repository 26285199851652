import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { GoBack } from "../components/GoBack";
import { urlClienti } from "../components/ApiURL";
import axios from "axios";
import { useForm } from "react-hook-form";
import Loading from "../components/Loading";

// nominativo
// email
// punti

// --- Elenco premi con relativi punti e data del riscatto

const CaricamentoClienti = () => {
  let navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const { idcliente } = useParams();

  const [client] = useState({
    nominativo: "",
    email: "",
    psw: (Math.random() + 1).toString(36).substring(7),
    punti: 0,
    datains: Date.now(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    axios
      .post(urlClienti, JSON.stringify(data))
      .then(setIsloading(true))
      .then((res) => {
        sessionStorage.setItem("clienti", JSON.stringify(res.data));
        reset(client);
        setIsloading(false);
        navigate("/clienti");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (typeof idcliente !== "undefined") {
      axios
        .get(urlClienti, { params: { id: idcliente } })
        .then(setIsloading(true))
        .then((res) => {
          reset(res.data[0]);
          setIsloading(false);
        });
    } else {
      reset(client);
    }
  }, [reset, client, idcliente]);

  return (
    <div className="container my-5">
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-5 mb-4 bg-light rounded-3">
            <div className="container-fluid py-5">
              <h1 className="display-5 mb-5 fw-bold">Dati Cliente</h1>
              <div className="form-floating mb-3">
                <input
                  className="form-control form-control-lg fs-4"
                  id="nominativo"
                  placeholder="Nominativo"
                  {...register("nominativo", { required: true })}
                />
                <label htmlFor="nominativo">Nominativo</label>
                {errors.nominativo && (
                  <span className="text-danger">
                    Inserire il nome del cliente
                  </span>
                )}
              </div>
              <div className="row">
                <div className="col-md-7">
                  <div className="form-row form-floating mb-3">
                    <input
                      type="email"
                      className="form-control form-control-lg fs-4"
                      id="email"
                      placeholder="email"
                      {...register("email", { required: true })}
                    />
                    <label htmlFor="email">Email</label>
                    {errors.email && (
                      <span className="text-danger">Inserire l'email</span>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-row form-floating mb-3">
                    <input
                      className="form-control form-control-lg fs-4"
                      id="psw"
                      placeholder="password"
                      {...register("psw", { required: true })}
                    />
                    <label htmlFor="email">Password</label>
                    {errors.psw && (
                      <span className="text-danger">
                        La password è obbligatoria
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-floating mb-3">
                    <input
                      type="number"
                      className="form-control form-control-lg fs-4"
                      id="punti"
                      placeholder="Nominativo"
                      {...register("punti", { required: true })}
                    />
                    <label htmlFor="punti">Punti</label>
                    {errors.punti && (
                      <span className="text-danger">Inserire i punti</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-grid">
                <button type="submit" className="btn btn-primary btn-lg fs-4">
                  Carica
                </button>
              </div>
            </div>
            <GoBack number={-1} color={"btn-success"} />
          </div>
        </form>
      )}
    </div>
  );
};

export default CaricamentoClienti;
