import React, { useState} from "react";
import { Route, Routes, Navigate} from "react-router-dom";
import Nav from "./components/Nav";
import ClientNav from "./frontend/home/Nav";
import Login from "./Login";
import CarimentoClienti from "./backend/CaricamentoClienti";
import CaricamentoPremi from "./backend/CaricamentoPremi";
import ElencoClienti from "./backend/ElencoClienti";
import ElencoPremi from "./backend/ElencoPremi";
import Premio from "./backend/Premio";
import RiepilogoPremi from "./backend/RiepilogoPremi";
import ModuloRiscatoPunti from "./frontend/ModuloRiscattoPunti";
import Home from "./frontend/home/Home";

import { getSessionStorageOrDefault } from "./components/Functions";
import { LoggedContext, UserContext } from "./components/Contexts";

import "./App.css";

//const currentTimeInMilliseconds=Date.now(); // unix timestamp in milliseconds

export const App = () => {
  const [logged, setLogged] = useState(
    getSessionStorageOrDefault("logged", false)
  );
  const [user, setUser] = useState(
    getSessionStorageOrDefault("user", {
      userid: "",
      username: "",
      login: "",
      password: "",
      punti: "",
      rule: "",
    })
  );
  return (
    <LoggedContext.Provider value={[logged, setLogged]}>
      <UserContext.Provider value={[user, setUser]}>
        {!logged ? (
          <Login />
        ) : user.rule === "admin" ? (
          <>
            <Nav />
            <Routes>
              <Route path="/" element={<RiepilogoPremi />} />
              <Route
                path="/clientForm/:idcliente"
                element={<CarimentoClienti />}
              />
              <Route path="/clientForm" element={<CarimentoClienti />} />
              <Route path="/clienti" element={<ElencoClienti />} />
              <Route
                path="/premiForm/:idpremio"
                element={<CaricamentoPremi />}
              />
              <Route path="/premiForm" element={<CaricamentoPremi />} />
              <Route path="/premi" element={<ElencoPremi />} />
              <Route path="/premio/:idpremio" element={<Premio />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </>
        ) : (
          <>
            <ClientNav />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/premi" element={<ModuloRiscatoPunti />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </>
        )}
      </UserContext.Provider>
    </LoggedContext.Provider>
  );
};

export default App;
