import React, { useContext } from "react";
import { LoggedContext} from "../../components/Contexts";
import { NavLink } from "react-router-dom";
import "./style.css";
import Logo from "../../Logo300.png"

const Nav = () => {
  const [logged, setLogged] = useContext(LoggedContext);

  const handleLogout = () => {
    console.log(logged);
    sessionStorage.clear();
    setLogged(false);
  };
  return (
    <nav className="navbar navbar-expand-lg align-items-center navbar-info bg-info mb-5">
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <img src={Logo} alt="logo" className="img-fluid meta-logo"/>
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon bg-dark"></span>
    </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-link text-white" : "nav-link"
                }
                aria-current="page"
                to="/"
              >
                Home
              </NavLink>
            </li>
          </ul>
          <span className="navbar-text">
            <button
              type="button"
              className="btn btn-link fs-3 text-dark"
              onClick={() => handleLogout()}
            >
              <i className="bi bi-person-x-fill"></i>
            </button>
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
