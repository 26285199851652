import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../components/Contexts";
import {
  urlClientePremi,
  urlClientiPuntiUpdate,
  urlClientiRiscattoPremi,
} from "../../components/ApiURL";
import axios from "axios";
import "./style.css";

const Home = () => {
  const [showForm, setShowForm] = useState(true);
  const [msg, setMsg] = useState({ show: false, content: "", bg: "info" });
  const [user] = useContext(UserContext);
  const [puntiRimasti, setPuntiRimasti] = useState(parseInt(user.punti));
  const [obj, setObj] = useState([]);
  const [premio, setPremio] = useState([
    {
      id: "",
      premi: [],
      documento: "",
      datains: "",
      attivo: "",
    },
  ]);

  const confronta = (xpunti) => {
    if (parseInt(xpunti) <= parseInt(puntiRimasti)) {
      return false;
    }
    if (parseInt(xpunti) > parseInt(puntiRimasti)) {
      return true;
    }
  };

  const handleSubmit = () => {
    //upgdate user scores
    //aggiunger ip premi riscattati (obj)
    user.punti = puntiRimasti;

    const userUpdate = async () => {
      await axios
        .get(urlClientiPuntiUpdate, {
          params: { punti: puntiRimasti, id: user.userid },
        })
        .then((res) => {
          const apiUser = JSON.stringify(res.data[0]);
          sessionStorage.setItem("user", apiUser);
          setShowForm(false);
          setMsg({
            show: true,
            content: "Richiesta riscatto premio inviato con successo",
            bg: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const riscattoAdd = async (item) => {
      await axios.post(urlClientiRiscattoPremi, item).catch((err) => {
        console.log(err);
      });
    };
    obj.forEach((item) => {
      item.iduser = user.userid;
      item.username = user.username;
      riscattoAdd(JSON.stringify(item));
    });
    userUpdate();
  };

  const handleClick = (isChecked, idpremio, idpremi, premio, punti) => {
    if (isChecked === true) {
      setObj([
        ...obj,
        {
          iduser: user.iduser,
          username: user.nominativo,
          puntiaccumulati: puntiRimasti,
          idpremio: idpremio,
          idpremi: idpremi,
          premio: premio,
          puntipremio: punti,
          puntirimasti: String(parseInt(puntiRimasti) - parseInt(punti)),
          datains: Date.now(),
        },
      ]);
      setPuntiRimasti(parseInt(puntiRimasti) - parseInt(punti));
    } else {
      const objArr = obj.filter((item) => item.idpremi !== idpremi);
      setObj(objArr);
      setPuntiRimasti(parseInt(puntiRimasti) + parseInt(punti));
    }
  };
  useEffect(() => {
    const getData = async () => {
      await axios
        .get(urlClientePremi)
        .then((res) => {
          setPremio(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, []);

  useEffect(() => {
    console.log("Punti rimasti", puntiRimasti);
    console.log("obj", obj);
  }, [puntiRimasti, obj]);

  return (
    <div className="container">
      <h1 className="display-5">{user.username.toUpperCase()}</h1>
      <div className="jumbotron my-3">
        <div className="container">
          <div className="h-100 p-5 text-white bg-dark rounded-3">
            <h1 className="fw-bold">
              HAI{" "}
              <span className="text-info display-4 fw-bold">
                {puntiRimasti}
              </span>{" "}
              PUNTI DA RISCATTARE
            </h1>
            <p className="lead">
              Scegli il premio nell'elenco qui sotto, consulta il file allegato
              per ulteriori informazioni e clicca sul bottone per richiedere il
              premio.
            </p>
            {showForm && (
              <button
                className={`btn btn-lg ${
                  puntiRimasti < user.punti ? "btn-info" : "btn-light"
                }`}
                type="button"
                onClick={() => puntiRimasti < user.punti && handleSubmit()}
              >
                INVIA RICHIESTA
              </button>
            )}
          </div>
        </div>
      </div>

      {msg.show && (
        <div className={`alert my-5 alert-${msg.bg}`}>{msg.content}</div>
      )}

      {showForm && (
        <>
          {premio ? (
            premio.map((item) => (
              <div className="container mb-5" key={item.id}>
                {item.documento && (
                  <div className="d-flex justify-content-end border-bottom border-dark">
                    <button
                      className="btn btn-link"
                      onClick={() =>
                        window.open(
                          `http://metacalabria.com/gestionale/uploads/${item.documento}`
                        )
                      }
                    >
                      <i className="bi bi-file-earmark-pdf fs-3 text-danger"></i>
                    </button>
                  </div>
                )}
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      {item.premi.map((pmio) => (
                        <tr className="border-none" key={pmio.id}>
                          <td>{pmio.premio}</td>
                          <td className="text-end fw-bold">Punti:</td>
                          <td className="text-end fw-bold">{pmio.punti}</td>
                          <td className="d-flex justify-content-end gap-3 align-items-start">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input bg-info border-dark"
                                type="checkbox"
                                onClick={(e) =>
                                  handleClick(
                                    e.target.checked,
                                    item.id,
                                    pmio.id,
                                    pmio.premio,
                                    pmio.punti
                                  )
                                }
                                disabled={confronta(pmio.punti)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))
          ) : (
            <div className="alert alert-info" role="alert">
              Nessun premio trovato
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Home;
