import React, { useContext } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { LoggedContext, UserContext } from "./Contexts";
import Logo from "../Logo300.png";

const Nav = () => {
  const locationPathName = useLocation().pathname;
  const [logged, setLogged] = useContext(LoggedContext);
  const [user] = useContext(UserContext);

  const handleLogout = () => {
    console.log(logged);
    sessionStorage.clear();
    setLogged(false);
  };

  const handleDropdownToggler = () => {
    document.getElementById("navdropdown").classList.remove("show");
  };
  
  return (
    <nav
      className="navbar navbar-expand-lg navbar-info shadow fixed"
      aria-label="Eighth navbar metacalabria"
      style={{ backgroundColor: "#0084c2" }}
    >
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <img src={Logo} alt="logo" className="img-fluid meta-logo" />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample07"
          aria-controls="navbarsExample07"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon bg-light"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarsExample07">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-link text-white" : "nav-link"
                }
                aria-current="page"
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-link text-white" : "nav-link"
                }
                aria-current="page"
                to="/clienti"
              >
                Clienti
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-link text-white" : "nav-link"
                }
                to="/premi"
              >
                Premi
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <NavLink 
                className={(locationPathName==='/premiForm' || locationPathName==='/clientform')
                    ? "nav-link dropdown-toggle text-white"
                    : "nav-link dropdown-toggle"
                } to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Aggiungi
              </NavLink>
              <ul onClick={()=>handleDropdownToggler()} id="navdropdown" className="dropdown-menu" aria-labelledby="navbarDropdown" style={{backgroundColor:"#0084C2",border:"none"}} >
              <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link to="/clientform" className="nav-link">Cliente</Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link to="/premiForm" className="nav-link">Premio</Link>
                </li>
              </ul>
            </li>
          </ul>
          <div className="d-inline-flex justify-content-end gap-1 align-items-center text-light">
            <span>{user.username}</span>
            <button
              type="button"
              className="btn btn-link text-dark fs-3"
              onClick={() => handleLogout()}
            >
              <i className="bi bi-person-x-fill"></i>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
