import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { urlPremi } from "../components/ApiURL";
import Loading from "../components/Loading";
import axios from "axios";

const Premio = () => {
  let navigate = useNavigate();
  const { idpremio } = useParams();
  const [premio, setPremio] = useState({});

  const [isLoading, setIsloading] = useState(false);

  const [premi, setPremi] = useState([
    { id: Date.now(), premio: "", punti: "" },
  ]);

  const handleRemovePremio = (id) => {
    window.confirm("Vuoi davvero eliminare?") &&
      axios
        .get(urlPremi, { params: { action: "del", id } })
        .then(setIsloading(true))
        .then(() => {
          setIsloading(false);
          navigate("/premi");
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    if (typeof idpremio !== "undefined") {
      axios
        .get(urlPremi, { params: { id: idpremio } })
        .then(setIsloading(true))
        .then((res) => {
          setPremio(res.data[0]);
          setPremi(JSON.parse(res.data[0].premi));
          setIsloading(false);
        });
    }
  }, [setPremio, idpremio]);

  return (
    <div className="container my-5">
      <div className="p-5 mb-4 bg-light rounded-3">
        <div className="container-fluid py-5">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <h1 className="display-5 mb-5 fw-bold">Dettaglio Premi</h1>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr className="border- none">
                      <th scope="col">#</th>
                      <th scope="col">Premio</th>
                      <th scope="col" className="text-end">
                        Punti
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {premi.map((item, index) => (
                      <tr key={index}>
                        <td className="fw-bold">{index + 1}</td>
                        <td>{item.premio}</td>
                        <td className=" text-end">
                          <span className="badge rounded-pill bg-success fw-bold fs-6">
                            {item.punti}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {idpremio && (
                <div className="d-flex my-5 justify-content-end gap-3 align-items-start">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleRemovePremio(idpremio)}
                  >
                    <i className="bi bi-trash-fill"></i>
                  </button>
                  {premio.documento && (
                    <button
                      className="btn btn-success"
                      onClick={() =>
                        window.open(
                          `https://metacalabria.com/gestionale/uploads/${premio.documento}`
                        )
                      }
                    >
                      <i className="bi bi-file-earmark-pdf"></i>
                    </button>
                  )}

                  <Link className="btn btn-primary ms-auto" to="/premi">
                    <i className="bi bi-arrow-left"></i>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Premio;
