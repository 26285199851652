import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { urlPremi } from "../components/ApiURL";
import { GoBack } from "../components/GoBack";
import Loading from "../components/Loading";
import axios from "axios";

// nominativo
// email
// punti

// --- Elenco premi con relativi punti e data del riscatto

const CaricamentoPremi = () => {
  let navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const { idpremio } = useParams(); // data inserimento
  const [premio, setPremio] = useState({});

  const [premi, setPremi] = useState([
    { id: Date.now(), premio: "", punti: "" },
  ]);

  const [file, setFile] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    const handleDocSubmit = async () => {
      const data = new FormData();
      data.append("datains", premio.datains ? premio.datains : Date.now());
      data.append("premi", JSON.stringify(premi));
      file && data.append("file", file);
      await axios
        .post(urlPremi, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(setIsloading(true))
        .then(() => {
          setIsloading(false);
          navigate("/premi");
        })
        .catch((error) => {
          console.log(error);
        });
    };
    handleDocSubmit();
  };

  const handleAddPremio = () => {
    setPremi([...premi, { id: Date.now(), premio: "", punti: "" }]);
  };

  const handleRemovePremio = (id) => {
    const splicePremi = premi.filter((premio) => premio.id !== id);
    if (splicePremi.length > 0) {
      setPremi(splicePremi);
    }
  };

  const handlePremiChange = (index, e) => {
    const values = [...premi];
    if (e.target.type === "checkbox") {
      values[index][e.target.name] = e.target.checked;
      setPremi(values);
    } else {
      values[index][e.target.name] = e.target.value;
      setPremi(values);
    }
  };

  useEffect(() => {
    if (typeof idpremio !== "undefined") {
      axios
        .get(urlPremi, { params: { id: idpremio } })
        .then(setIsloading(true))
        .then((res) => {
          setPremio(res.data[0]);
          setPremi(JSON.parse(res.data[0].premi));
          setIsloading(false);
        });
    }
  }, [setPremio, idpremio]);

  return (
    <div className="container my-5">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <form onSubmit={onSubmit}>
            <div className="p-5 mb-4 bg-light rounded-3">
              <div className="container-fluid py-5">
                <h1 className="display-5 mb-5 fw-bold">Inserimento bonus</h1>

                {premi.map((item, index) => (
                  <div className="row mb-3" key={index}>
                    <div className="col-10">
                      <div className="row">
                        <div className="col-md-2">
                          <input
                            type="number"
                            className="form-control form-control-lg"
                            name="punti"
                            placeholder="Punti"
                            defaultValue={item.punti}
                            onChange={(e) => handlePremiChange(index, e)}
                          />
                        </div>
                        <div className="col-md-10">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="premio"
                            placeholder="Bonus erogato"
                            defaultValue={item.premio}
                            onChange={(e) => handlePremiChange(index, e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-2 d-flex justify-content-end gap-3 align-items-center">
                      <button
                        type="button"
                        className="btn btn-sm bg-secondary"
                        onClick={() => handleRemovePremio(item.id)}
                      >
                        <i className="bi bi-dash-lg text-white"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        onClick={() => handleAddPremio()}
                      >
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="file"
                      name="file"
                      className="form-control form-control-lg"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                </div>
                {premio.documento && (
                  <div className="my-3">
                    <a
                      href={`https://metacalabria.com/gestionale/uploads/${premio.documento}`}
                      rel="noreferrer nofollow"
                      target="_blank"
                    >
                      Vedi il file
                    </a>
                  </div>
                )}
                <div className="d-grid mt-3">
                  <button type="submit" className="btn btn-primary btn-lg fs-4">
                    Carica
                  </button>
                </div>
              </div>
            </div>
          </form>
          <GoBack number={-1} color={"btn-success"} />
        </>
      )}
    </div>
  );
};

export default CaricamentoPremi;
