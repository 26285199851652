import CryptoJS from "crypto-js";

// convert timestampe (new Date().getTime()) in italian date
export const timestampToItDate = (inputFormat) => {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(parseInt(inputFormat));
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
};

export const timestampToItDateTime = (inputFormat) => {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(parseInt(inputFormat));
  var t =  pad(d.getHours()) +"."+ pad(d.getMinutes() +":"+ pad(d.getSeconds()));
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/") +" "+ t;
};

// convert timestampe (new Date().getTime()) in english date
export const timestampToEngDate = (inputFormat) => {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(parseInt(inputFormat));
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("-");
};

// get sessionstorage if exist else applique default value
export const getSessionStorageOrDefault = (key, defaultValue) => {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
};

// get localstorage if exist else applique default value
export const getLocalStorageOrDefault = (key, defaultValue) => {
  const stored = localStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
};

// Encrypt
export const encryptData = (data) =>{
return CryptoJS.AES.encrypt(JSON.stringify(data), 'MllMchl *!$^210962').toString();
}
// Decrypt
export const decryptData = (data) =>{
var bytes  = CryptoJS.AES.decrypt(data, 'MllMchl *!$^210962');
return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

// const singers = [
//         { name: 'Steven Tyler', band: 'Aerosmith', born: 1948 },
//         { name: 'Karen Carpenter', band: 'The Carpenters', born: 1950 },
//         { name: 'Kurt Cobain', band: 'Nirvana', born: 1967 },
//         { name: 'Stevie Nicks', band: 'Fleetwood Mac', born: 1948 },
//       ];
export function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

// Filter With Criter

export const filterWithCrtArray = () => {
  const raw = {
    item1: { key: "sdfd", value: "sdfd" },
    item2: { key: "sdfd", value: "sdfd" },
    item3: { key: "sdfd", value: "sdfd" },
  };

  const allowed = ["item1", "item3"];

  const filtered = Object.keys(raw)
    .filter((key) => allowed.includes(key))
    .reduce((obj, key) => {
      obj[key] = raw[key];
      return obj;
    }, {});

  console.log(filtered);
};

export const removeNonAlphaNumeric = (inputName) => {
  //input.replace(/\W/g, '') //doesnt include underscores
  // input.replace(/[^0-9a-z]/gi, '') //removes underscores too
  return inputName.replace(/[^0-9a-z.]/gi, ''); // non remove il punto
};

export const badgeBgColor = (valore) => {
  let bg = "bg-info";
  if(valore === 'Completato'){
    bg="bg-success";
  }
  if(valore === 'In lavorazione'){
    bg="bg-primary";
  }
  if(valore === 'In attivazione'){
    bg="bg-info";
  }
  if(valore === 'Da controllare'){
    bg="bg-warning"
  }
  if(valore === 'KO'){
    bg="bg-danger"
  }
  return bg;
}