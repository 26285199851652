import React from "react";

const PremiSearchBar = ({ textToSearch, crt, settexttosearch, setcrt }) => {
  return (
    <div className="input-group mb-3">
      <input
        type="text"
        className="form-control form-control-lg bg-white"
        placeholder={`Cerca per ...`}
        value={textToSearch}
        onChange={(e) => settexttosearch(e.target.value)}
      />
      <select
        className="bg-info px-3 border-0"
        defaultValue={crt}
        onChange={(e) => setcrt(e.target.value)}
      >
        <option value="datains">Data inser</option>
        <option value="username">Nominativo</option>
        <option value="puntirimasti">Punti rimasti</option>
        <option value="puntiaccumulati">Punti accumulati</option>
      </select>
    </div>
  );
};

export default PremiSearchBar;
